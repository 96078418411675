<template>
  <div v-cloak class="video_container">
    <div class="video">
      <iframe
        :src="$route.query.src"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      ></iframe>
    </div>
    <div class="chat-container">
      <div class="title">Chat</div>
      <ul class="chatClass p-0" ref="scrollbox">
        <li
          :class="{
            join: item.msgTag == 'tag',
            right: item.msgTag == 'self',
          }"
          v-for="(item, index) in ChatList"
          :key="index"
        >
          <span>{{ item.nick_name }}</span>
          <div class="msg-box">
            <div class="msg-content">
              {{ item.content }}
            </div>
            <div class="time">{{ item.send_time | filterTime }}</div>
          </div>
        </li>
      </ul>
      <div class="send">
        <el-input
          type="textarea"
          resize="none"
          :rows="5"
          placeholder="Type your message here..."
          v-model="msg"
          @keyup.enter.native="sendMsg"
        ></el-input>
        <el-button type="primary" size="small" @click="sendMsg">Send</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { chatWsUrl } from '../../global'
export default {
  data () {
    return {
      msg: '',
      ws: {},
      timer: 0,
      isHeartbeat: true,
      loading: false,

      page: 1,

      dataLength: true,
      chatWsUrl

    }
  },
  created () {
    // 进入页面时要清空以前的chatList
    this.clearChatList()
    this.userHasPowerClickLink()
    this.innitChat()

    // 获取聊天记录
    this.getModelChat()
  },

  mounted () {
    let scrollbox = this.$refs.scrollbox
    scrollbox.addEventListener('scroll', this.handleScroll)
  },

  computed: {
    ...mapState({
      ChatList: (state) => state.meetingChat.ChatList
    })
  },
  filters: {
    filterTime (val) {
      let timeArr = val.split(' ')
      return timeArr[1]
    }
  },
  methods: {
    ...mapMutations(['bindUser', 'SaveChatData', 'clearChatList', 'saveChatList']),

    // 监听聊天----向上滚动获取聊天记录
    handleScroll (val) {
      let box = this.$refs.scrollbox
      if (box.scrollTop == 0 && this.dataLength) {
        // 滚动到顶部
        this.page++
        this.getModelChat()
      }
    },

    // 获取聊天记录
    getModelChat () {
      let { id, type } = this.$route.query
      this.$http.getModelChat({
        model_str: `event_chat${id}${type}`,
        page: this.page,
        size: 20
      }).then(res => {
        if (res.data.data.length == 0) {
          this.dataLength = false
          return
        };
        // 将接口返回的数据调换顺序
        let rever = res.data.data.reverse()
        // 将滚动条滚至最下方
        this.$nextTick().then(() => {
          if (this.$refs.scrollbox) {
            this.$refs.scrollbox.scrollTop = this.$refs.scrollbox.scrollHeight
          }
        })
        this.saveChatList(res.data.data)
      })
    },

    // initChat
    innitChat () {
      let that = this;
      that.ws = new WebSocket(chatWsUrl);

      // 接收到服务器信息
      that.ws.onmessage = function (evt) {
        const res = JSON.parse(evt.data)
        // 接收用户身份信息---打开心跳
        if (res.type == 'event_bind') {
          that.bind(res)
          that.addModelChat();
        }

        if (res.model_str != undefined) {
          that.SaveChatData({ ...res })
        }

        // 将滚动条滚至最下方
        that.$nextTick().then(() => {
          if (that.$refs.scrollbox) {
            that.$refs.scrollbox.scrollTop = that.$refs.scrollbox.scrollHeight
          }
        })
      }

      // 开启以后绑定用户身份--发送消息
      that.ws.onopen = async function (evt) {
        let token = localStorage.getItem('token')
        if (evt.isTrusted) {
          that.isHeartbeat = true
          that.ws.send(JSON.stringify({ type: 'event_bind', authorization: token }))
        }
      }

      that.ws.onclose = function (e) {
        console.log('video 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
        clearInterval(this.timer)
        // 重连
        reconnect();
        function reconnect () {
          setTimeout(() => {
            that.innitChat('reconnect');
          }, 100)
        }
      }
    },
    // 绑定客户端 上线client_id绑定user_id
    bind (res) {
      let { user_id, client_id, nick_name, avatar } = res
      this.userInfo = res
      this.bindUser({ nick_name, avatar, client_id, user_id });
      // 握手防止掉线
      this.handshake()
    },
    // 心跳
    handshake () {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.isHeartbeat) {
          this.ws.send(
            JSON.stringify({
              type: 'Heartbeat'
            })
          )
        } else {
          this.isHeartbeat = false;
          clearInterval(this.timer)
        }
      }, 90000)
    },
    // 加入会议
    async addModelChat () {
      let { id, type } = this.$route.query
      let res = await this.$http.addModelChat({
        model_str: `event_chat${id}${type}`,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    },

    // 发送消息
    sendMsg () {
      if (!this.msg) return
      let { id, type } = this.$route.query
      this.$http.sendModelChat({
        content: this.msg,
        model_str: `event_chat${id}${type}`,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
      }).then(res => {
        if (res.status == 200) {
          this.msg = ''
        }
      })
    },


    async userHasPowerClickLink () {
      let token = localStorage.getItem('token')
      let getToken = token || ''
      if (!getToken) {
        this.$router.push('/sign-in1')
        return
      }
      let { id, my_schedule_id, type } = this.$route.query

      let ajaxType = { 1: 'userHasPowerClickLink', 2: 'eventUserHasPowerClickLink', 3: 'courseUserHasPowerClickLink', 4: 'meetingUserHasPowerClickLink' }
      let typeId = { 1: 'project_id', 2: 'event_id', 3: 'course_id', 4: 'meeting_id' }

      let res = await this.$http[ajaxType[type]]({
        [typeId[type]]: id,
        my_schedule_id: my_schedule_id,
        my_schedule_model_type: type,
        link_type: 'broadcast_link'
      })
      if (res.status == 6082) {
        this.$router.push('/newsfeeds')
        // history.go(-1);
      }
    }
  }
}
</script>

<style scoped lang="less">
[v-cloak] {
  display: none;
}
.video_container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  > .video {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 450px);
    height: 100%;
    background: #fff;
  }

  > .chat-container {
    width: 450px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border-left: 1px solid #f1f1f1;
    .title {
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 20px;
      color: #333;
      border-bottom: 1px solid #f1f1f1;
    }

    .chatClass {
      display: flex;
      flex-direction: column;
      height: calc(100% - 270px);
      overflow: auto;
      border-bottom: 1px solid #f1f1f1;
      background: url(./100.jpg) no-repeat scroll center center;
      background-size: cover;

      > li {
        padding: 10px 20px;
        list-style: none;
        > span {
          color: #50b5ff;
        }
        .msg-box {
          display: flex;
          align-items: flex-end;
          .msg-content {
            background: #fff;
            border-radius: 0px 12px 12px 12px;
            padding: 10px;
            color: #333;
            font-size: 14px;
            /* word-break: break-all; */
          }
          .time {
            color: #999;
            margin-left: 18px;
          }
        }
      }

      // 右边聊天
      > li.right {
        padding: 10px 20px;
        list-style: none;

        > span {
          display: block;
          text-align: right;
        }
        > .msg-box {
          display: flex;
          // justify-content: flex-end;
          flex-direction: row-reverse;
          align-items: flex-end;
          .msg-content {
            max-width: 70%;
            background: #fff;
            border-radius: 12px 0px 12px 12px;
            padding: 10px;
            color: #333;
            font-size: 14px;
            /* word-break: break-all; */
          }
          .time {
            color: #999;
            margin-right: 18px;
          }
        }
      }

      // 加入聊天提醒
      > li.join {
        display: flex;
        justify-content: center;
        .msg-content {
          background: rgba(0, 0, 0, 0.1);
          color: #fff;
          border-radius: 5px;
          padding: 5px 10px;
          font-size: 12px;
        }
      }
    }
    .send {
      width: 100%;
      height: 180px;
      position: absolute;
      bottom: 0;
      left: 0;

      ::v-deep .el-textarea__inner {
        // padding: 0;
        border: none;
      }

      .el-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>
